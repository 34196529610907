.header {
  background: #ffffff00;
  color: #ffffff;
  position: absolute;
  width: 100%;
  transition: 0.2s ease-in-out;
  z-index: 10000;
}

.sticky {
  position: fixed;
  top: 0;
  width: 98%;
  background: white;
  border-radius: 1vw;
  margin-top: 1vh;
  margin-left: 1vw;
  margin-right: 1vw;
  transition: 0.2s ease-in-out;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
/* .sticky + .content {
  padding-top: 102px;
} */

.main-view-container {
  overflow-x: hidden;
  width: 100vw;
}

.main-view-img {
  width: 100%;
  z-index: 20;
  user-select: none; /* Prevent text selection */
  -webkit-user-drag: none; /* Prevent image dragging in WebKit browsers */
  -webkit-touch-callout: none; /* Prevent callout on long press in iOS */
}

.main-view-title {
  position: absolute;
  left: 4vw;
  top: 24vh;
  z-index: 20;
}

.header-logo {
  width: auto;
  height: 5vh;
}

.header-btn {
  font-weight: 600;
  transition: 0.1s ease-in-out;
  padding: 1vh;
}

.header-btn:hover {
  background-color: #484ca141;
  border-radius: 0.5vw;
  cursor: pointer;
}

.main-view-title-text {
  font-size: 6vw !important;
  font-weight: 900 !important;
  color: white !important;
}
.primary-color {
  color: #484da1;
}

.section-wrapper {
  width: 100vw !important;
  margin: 10vh 0vh;
}

.section-container {
  display: flex !important;
  justify-content: center !important;
}

.section-width {
  width: 70vw !important;
}

.section-img {
  height: auto;
  max-width: 32vw;
}

@media (max-width: 650px) {
  .section-img {
    height: 50vh;
    width: auto;
    min-width: 70vw;
  }
  .section-img-container {
    justify-content: center;
  }

  .how-we-work-title {
    align-self: center;
    font-weight: 600;
  }

  .point-icon-wrapper {
    width: 5.25vw !important;
    height: 4.25vw !important;
  }
  .hidden-on-small {
    display: none;
  }
  .main-view-img {
    height: 46vh !important;
  }

  .contact-card-container {
    /* width: 100% !important; */
    height: 10vh !important;
    padding-left: 1vh;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .contact-card-grid-container {
    height: 10vh !important;
  }

  .contact-us-icon-container {
    padding: 1.8vh;
  }
  .contact-card-title {
    font-size: 1.5vh !important;
  }
  .contact-card-text {
    font-size: 1.6vh !important;
  }

  .contact-us-icon {
    font-size: 4vw !important;
  }
  .send-btn-title {
    font-size: 1.2vh !important;
  }

  .contact-us-form-container {
    width: 80vw !important;
    height: 0 !important;
  }
}
.section-title {
  font-size: 3.7vw !important;
  font-weight: 600 !important;
}

.section-description {
  font-size: 2vh;
  font-weight: 500;
  text-align: justify;
  line-height: 2.8vh;
}

.line {
  width: 2.6vw;
  height: 0.7vh;
  background-color: #484da1;
  border-radius: 1vh;
  margin-top: -0.7vh;
  transition: 0.5s ease-in-out;
}

.how-we-work-title {
  align-self: center;
  font-size: 1.5vw;
  font-weight: 600;
}

.section-hover-effect:hover {
  .about-us-line {
    width: 17vw;
  }
  .our-mission-line {
    width: 22vw;
  }
  .how-we-work-line {
    width: 25vw;
  }
  .contact-us-line {
    width: 36vw;
  }
}

.point-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #484ca157;
  border-radius: 3vw;
  width: 1.25vw;
  height: 1.25vw;
  padding: 0.5vw;
}

.point-icon {
  font-size: 1vw;
}

.download-btn {
  color: #484da1;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}

.hoverable-btn {
  transition: 0.2s ease-in-out;
}

.hoverable-btn:hover {
  background-color: #484ca156;
  color: white;
  cursor: pointer;
}

.contact-card-title {
  font-size: 2.1vh;
  font-weight: 600 !important;
  color: #959595 !important;
  margin-top: 0px;
  margin-bottom: 0px;
}

.contact-card-text {
  font-size: 2.8vh;
  font-weight: 600 !important;
  margin-top: 1vh;
  margin-bottom: 0px;
}
